@import './src/styles/_global.scss';
@import url('https://fonts.googleapis.com/css?family=Barlow:300,400,500,600,600,700,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Barlow+Semi+Condensed:300,400,500&display=swap');

:root {
	--link-color: #1e5a8e;
}

* {
	box-sizing: border-box;
}

html {
	font-size: 10px;
	@media print {
		font-size: 8px;
	}
}

body {
	margin: 0;
}

body,
input,
button {
	font-family: $font-primary;
	color: $color-body;
	font-size: 2rem;
	line-height: 1.777;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
	min-width: 100vw;
	min-height: 100vh;
}

#root {
	display: flex;
	flex-direction: column;
	height: 100%;
}

a {
	text-decoration: none;
	color: var(--link-color);
}

:global {
	.osano-cm-window {
		@media print {
			display: none !important;
		}
	}
}
