$font-primary: 'Barlow', sans-serif;
$font-condensed: 'Barlow Semi Condensed', sans-serif;

$gutter: 2rem;

$color-body: #3a3a44;

$color-white: #ffffff;

$color-grey: #f1f1f2;
$color-grey-dark: #75757c;
$color-grey-bg: #d0d0ce;

$color-blue: #1e5a8e;
$color-blue-dark: #02355c;

$color-gold: #e7ad52;
$color-gold-dark: #df9218;

$color-red: #c81818;

$color-btn-bg: $color-grey-bg;
$color-btn-accent: $color-gold-dark;

$color-video-accent: $color-gold-dark;
